<template>
  <div class="product-card" :class="{ 'product-card--small': small }" v-if="data">
    <div class="product-card__top">
      <div class="product-card__top-col">
        <span class="product-card__discount" v-if="data.join_share && data.join_share.share">
          {{ data.join_share.share.percent }} %
        </span>
        <span class="product-card__wholesale" v-if="data.in_wholesale"> Оптом </span>
        <span class="product-card__retail" v-if="data.in_wholesale"> Розница </span>
        <!--        <span class="product-card__size">Размер: M</span>-->
      </div>
      <div class="product-card__top-col product-card__top-col--right">
        <button
          :class="{ 'product-card__favorite--active': inFavorites }"
          type="button"
          class="product-card__favorite"
          title="В избранное"
          @click.prevent="addToFavorites"
        >
          <FileIconComponent v-if="loading" name="loading" />
          <RemixIconComponent v-else category="Health" name="heart-3-line" />
        </button>
      </div>
    </div>
    <div class="product-card__image-container">
      <ImgComponent :head_img="data_img" :width="750" :height="1000" class="product-card__image" />
      <span class="product-card__image-hover">
        <router-link
          v-if="data.title"
          :to="{
            name: 'product',
            params: { id: data.parent_id ? data.parent_id : data.id, link: data.link },
          }"
        ></router-link>
        <button type="button" @click="showPreviewModal" class="btn btn--sm btn--white">
          <RemixIconComponent category="System" name="eye-line" />
          <span>Быстрый просмотр</span>
        </button>
      </span>
    </div>
    <div class="product-card__content">
      <div class="product-card__reviews" v-if="data.reviews">
        <div class="product-card__reviews-stars">
          <RemixIconComponent
            class="product-card__reviews-star"
            :class="{ 'product-card__reviews-star--filled': averageReviewsMark >= i }"
            category="System"
            name="star-fill"
            v-for="i in 5"
            :key="i"
          />
        </div>
        <span class="product-card__reviews-count">({{ data.reviews.length }})</span>
      </div>
      <div class="product-card__title">
        <router-link
          v-if="data.brand && data.brand.title"
          class="product-card__company"
          :to="{ name: 'catalog', query: { brand_id: data.brand.id, page_title: data.brand.title } }"
        >
          {{ data.brand.title }}
        </router-link>
        <span v-if="data.brand && data.brand.title"> / </span>
        <router-link
          v-if="data.title"
          :to="{
            name: 'product',
            params: { id: data.parent_id ? data.parent_id : data.id, link: data.link },
          }"
        >
          {{ data.title }}
        </router-link>
      </div>
    </div>
    <div class="product-card__bottom">
      <router-link
        :to="{
          name: 'product',
          params: {
            id: data.parent_id ? data.parent_id : data.id,
            link: data.link || (data.parent && data.parent.link),
          },
        }"
        class="product-card__prices"
        v-if="data.price"
      >
        <span class="product-card__price">
          {{ data.price | share_price(data) | price_from_dollar($store.state) }}
        </span>
        <span v-if="data.join_share && data.join_share.share" class="product-card__old-price">
          {{ data.price | price_from_dollar($store.state) }}
        </span>
      </router-link>
      <button @click="showPreviewModal" type="button" class="btn btn--main btn--sm">
        <RemixIconComponent category="Finance" name="shopping-cart-2-line" />
        <span>Купить</span>
      </button>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import ProductPreviewModalComponent from "components/modals/components/ProductPreviewModalComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import FileIconComponent from "components/FileIconComponent.vue";

export default {
  name: "ProductCardComponent",
  props: {
    data: Object,
    small: Boolean,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    averageReviewsMark() {
      if (this.data && this.data.reviews && this.data.reviews.length) {
        let length = this.data.reviews.length;
        return Math.floor(this.data.reviews.map((r) => parseInt(r.mark)).reduce((a, b) => a + b, 0) / length);
      }
      return 0;
    },
    data_img() {
      if (this.data.head_img) {
        return this.data.head_img;
      }
      if (this.data.images && this.data.images.length) {
        return this.data.images[0];
      }
      return null;
    },
    inFavorites() {
      return this.$store.state.auth.favorites.includes(this.data.id);
    },
    isRegistered() {
      return !!this.$store.state.apollo_token;
    },
  },
  methods: {
    async addToFavorites() {
      if (this.isRegistered) {
        let index = this.$store.state.auth.favorites.indexOf(this.data.id);
        if (index !== -1) {
          this.$store.state.auth.favorites.splice(index, 1);
        } else {
          this.$store.state.auth.favorites.push(this.data.id);
        }
        await this.$store.dispatch("FAVORITE_CREATE_OR_DELETE", {
          apollo: this.$apollo,
          variables: {
            item_id: this.data.id,
          },
        });
      } else {
        this.showLoginModal();
      }
    },
    showPreviewModal() {
      this.$store.state._modals.push({
        component: ProductPreviewModalComponent,
        options: {
          data: this.data,
        },
      });
    },
    showLoginModal() {
      this.$store.state._modals.push({
        component: LoginModal,
        options: {},
      });
    },
  },
  components: { FileIconComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.product-card {
  background: var(--white);
  border-radius: var(--main_radius);
  padding 8px 8px 22px
  display inline-flex
  flex-direction column
  gap 16px
  position relative
  border: 1px solid var(--blue-400);
  width 100%
  height: 100%

  &__favorite {
    border-radius: var(--main_radius);
    border none
    background none
    cursor pointer
    padding 10px
    z-index 1
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background var(--blue-300)
    }

    &.product-card__favorite--active {
      .icon svg path {
        fill var(--accent)
      }
    }

    .icon {
      width 24px
      height 24px

      svg {
        path {
          fill var(--gray-500)
        }

        circle {
          stroke var(--gray-500)
        }
      }
    }
  }

  &__top {
    absolute left top
    width 100%
    display flex
    justify-content space-between
    align-items flex-start
    padding 18px
  }

  &__top-col--right {
    align-items flex-end
  }

  &__top-col {
    display flex
    flex-direction column
    align-items flex-start
    gap 8px
		+below(720px) {
			gap 4px
		}
  }

  &__size
  &__discount
  &__wholesale
  &__retail {
    background: var(--red-500);
    border-radius: 4px;
    font-weight: normal;
    font-size: 0.75em;
    line-height: 16px;
    padding 4px
    color: var(--white);
    z-index 1
  }

  &__wholesale {
    background var(--green)
  }

  &__retail {
    background var(--accent)
  }

  &__size {
    background: var(--white);
    color: var(--body_color_muted);
    border: 1px solid var(--blue-400);
  }

  &--small &__image {
    height 200px
    min-height 200px
    +below(860px) {
      height 200px
      min-height 200px
    }
  }

  &__image {
    object-fit contain
    object-position center
    border-radius: var(--main_radius);
    height 220px
    min-height 220px
  }

  &__image-hover {
    absolute left top right bottom
    margin auto
    width: 100%;
    height: 100%;
    border-radius: var(--main_radius);
    display flex
    align-items center
    justify-content center
    opacity 0
    transition opacity var(--transition)
    background: rgba(80, 92, 103, 0.8);

    a {
      width 100%
      height 100%
      absolute left top
    }

    .btn {
      z-index 1
    }
  }

  &__image-container {
    position relative
    background: none;
    border: none;

    &:hover ^[0]__image-hover {
      opacity 1
    }
  }

  &__content {
    display flex
    flex-direction column
    gap 8px
  }

  &__reviews {
    display inline-flex
    align-items center
    justify-content flex-start
    gap 8px
  }

  &__reviews-stars {
    display inline-flex
    align-items flex-start
    justify-content flex-start
  }

  &__reviews-star {
    width 14px
    height 14px

    &:not(&--filled) {
      svg path {
        fill transparent
        stroke var(--yellow)
      }
    }

    &--filled {
      svg path {
        fill var(--yellow)
      }
    }
  }

  &__reviews-count {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--body-color-muted);
  }

  &__title {
    & * {
      font-weight: normal;
      font-size: 0.875em;
      line-height: 20px;
      color var(--gray-300)
    }
  }

  &__company {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--body-color-muted);
  }

  &--small &__bottom {
    +below(540px) {
      flex-direction column
      flex: 1;

      .btn {
        width 100%
        flex-shrink: 0;
        +below(540px) {
          justify-content center
        }
      }
    }
  }

  &__bottom {
    display flex
    align-items center
    justify-content space-between
    gap 16px
    padding 8px
    margin-top auto
  }

  &--small &__prices {
    +below(540px) {
      width: 100%;
      flex-direction: revert;
      justify-content: flex-start;
      align-items: center;
      gap 4px
    }
  }

  &__prices {
    display flex
    flex-direction column
    align-items flex-start
    justify-content center
  }

  &__price {
    font-weight: 600;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--gray-900);
    white-space nowrap
  }

  &__old-price {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    color: var(--gray-300);
  }
}
</style>
