<template>
  <router-link
    :to="{
      name: 'catalog',
      query: { share_id: data.id, in_share: true, parent: true, page_title: data.title },
    }"
    class="main-slide"
    v-if="data"
  >
    <div class="main-slide__image-container">
      <ImgComponent
        v-if="$store.state._window_size > 860"
        class="main-slide__image"
        :head_img="data.head_img"
        :loader="false"
      />
      <ImgComponent
        v-else
        class="main-slide__image-mobile"
        :head_img="data.head_img_mobile || data.head_img"
        :loader="false"
      />
    </div>
    <div class="main-slide__content">
      <h2 class="main-slide__title" :title="data.title">{{ data.title }}</h2>
      <p class="main-slide__description">{{ data.subtitle }}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "SlideComponent",
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/mixins/textcut.styl"
.main-slide {
  background: var(--white);
  border-radius: var(--main_radius);
  height 333px
  position relative
  overflow hidden
  display inline-flex
  align-items flex-start
  justify-content flex-start
  min-width 450px
  width 100%
  +below(860px) {
    height auto
    min-height 300px
    min-width auto
  }
  +below(540px) {
    min-height auto
  }

	&:hover {
		opacity 0.9
	}

  &__content {
    absolute left bottom
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-end
		gap: 10px
    z-index 1
    padding 15px 30px
    +below(860px) {
      justify-content center
      align-items center
      width 100%
      height 100%
    }
  }

  &__title {
    font-weight: normal;
    font-size: 2.25em;
    line-height: 1;
    display: flex;
    align-items: center;
    color: var(--white);
    margin 0;
		textcut(2);
    +below(860px) {
      font-size: 1.5em;
      line-height: 32px;
      text-align center
    }
  }

  &__description {
    font-weight: normal;
    font-size: 1.125em;
    line-height: 1.2;
    display: flex;
    align-items: center;
    color: var(--white);
    opacity: 0.8;
    margin 0
		textcut(2);
    +below(860px) {
      font-size: 0.875em;
      line-height: 20px;
      text-align center
    }
  }

  &__image {
    width 100%
    height 100%
    object-fit cover
    object-position center
		filter: brightness(0.8)
    +below(860px) {
      display none
    }

    &-container {
      width 100%
      height 100%
      +below(860px) {
        ratio(450, 300)
      }
    }

    &-mobile {
			filter: brightness(0.9)
      +above(861px) {
        display none
      }
    }
  }
}
</style>
