<template>
  <div class="site-description">
    <article
      :class="{ 'site-description__container--full': !marketplace.head_img }"
      class="site-description__container"
    >
      <ImgComponent
        class="site-description__image"
        :width="416"
        :head_img="marketplace.head_img"
        v-if="marketplace.head_img"
      />
      <div class="site-description__article">
        <h1 class="site-description__title">{{ marketplace.title }}</h1>
        <EditorJSComponent v-if="marketplace.description" :text="marketplace.description" />
      </div>
    </article>
  </div>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "SiteDescriptionComponent",
  props: {
    marketplace: Object,
  },
  components: { EditorJSComponent },
};
</script>

<style lang="stylus">
.site-description {
  display flex
  justify-content center
  align-items center
  padding 0 15px

  &__container {
    max-width var(--small_width)
    width 100%
    display grid
    align-items center
    grid-gap 24px
    grid-template-columns 4fr 6fr
    +below(1024px) {
      grid-template-columns 5fr 5fr
    }
    +below(860px) {
      grid-template-columns 1fr
    }

    &--full {
      grid-template-columns 1fr
    }
  }

  &__image {
    object-fit contain
    object-position center
    width auto
    height 100%
    max-height 340px
    margin 0 auto
    border-radius: var(--main_radius);
  }

  &__article {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    white-space pre-line

    p {
      font-weight: normal;
      font-size: 0.875em;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: var(--body-color-muted);
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: var(--gray-900);
  }
}
</style>
