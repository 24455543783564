<template>
  <router-link
    :to="{ name: 'catalog', params: { id: data.id }, query: { page_title: data.title } }"
    class="main-category"
  >
    <div class="main-category__img">
      <ImgComponent :width="900" :height="1050" :head_img="data.head_img" />
    </div>
    <!--<ImgComponent :head_img="data.head_img" />-->
    <span>{{ data.title }}</span>
  </router-link>
</template>

<script>
export default {
  name: "MainCategoryItem",
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.main-category {
  background: var(--white);
  border-radius: var(--main_radius);
  display inline-flex
  flex-direction column
  align-items center
  justify-content flex-start
  padding 8px 8px 16px
  gap 16px
  height auto
  border: 1px solid var(--blue-400);
  width 100%

  &:hover {
    border-color var(--main)
  }

  &__img {
    ratio(290, 355)

    img {
      width 100%
      height 355px
      max-height 100%
      border-radius: var(--main_radius);
      object-fit cover
      object-position center
    }
  }

  span {
    width 100%
    text-align center
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--body-color-muted);
  }
}
</style>
