<template>
  <div v-if="brands && brands.length" class="brands">
    <div class="brands__container">
      <h3 class="brands__title">Бренды</h3>
      <ul class="brands__list">
        <li v-for="(item, i) in brands" :key="i">
          <router-link :to="{ name: 'catalog', query: { brand_id: item.id, page_title: item.title } }">
            <img
              :src="item.head_img | image($store.state._env.MEDIA_ENDPOINT)"
              :alt="item.head_img | image_alt"
              :title="item.title"
              loading="lazy"
            />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandsComponent",
  props: {
    brands: Array,
  },
};
</script>

<style lang="stylus">
.brands {
  display flex
  justify-content center
  padding 0 15px

  &__container {
    max-width var(--main_width)
    width 100%
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    gap 24px
  }

  &__title {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: var(--gray-900);
    margin 0
  }

  &__list {
    display grid
    grid-template-columns repeat(8, 1fr)
    grid-gap 20px
    width: 100%;
    +below(1024px) {
      grid-template-columns repeat(7, 1fr)
    }
    +below(940px) {
      grid-template-columns repeat(6, 1fr)
    }
    +below(860px) {
      grid-template-columns repeat(5, 1fr)
    }
    +below(680px) {
      grid-template-columns repeat(4, 1fr)
    }
    +below(540px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(420px) {
      grid-template-columns repeat(2, 1fr)
    }

    li {
      border: 1px solid var(--blue-300);
      box-sizing: border-box;
      border-radius: var(--main_radius);
      height 80px
      background var(--white)
      overflow hidden
      transition var(--transition)

      &:hover {
        filter brightness(.9)
      }

      img {
        width 100%
        height 100%
        object-fit contain
        object-position center
      }
    }
  }
}
</style>
