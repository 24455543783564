<template>
  <router-link
    :to="{ name: 'share', params: { id: data.id } }"
    v-if="data"
    class="promo-item"
    :title="data.head_img | image_alt"
  >
    <ImgComponent class="promo-item__image" :height="700" :width="700" :head_img="data.head_img" />
  </router-link>
</template>

<script>
export default {
  name: "PromoComponent",
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus">
.promo-item {
  border-radius: var(--main_radius);
  overflow hidden
  height 196px
  width 100%

	&:hover {
		filter brightness(0.9)
	}

  &__image {
    width 100%
    height 100%
    object-fit cover
    object-position center
  }
}
</style>
