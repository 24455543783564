<template>
  <div class="page home-page">
    <HomeStoriesComponent :stories="stories" />
    <SliderComponent
      v-if="slides.length"
      class="home-page__main-slider"
      :items="slides"
      v-slot="{ item }"
      :slider-options="mainSliderOptions"
    >
      <SlideComponent :data="item" />
    </SliderComponent>
    <OpenCatalogButtonComponent />
    <SliderComponent
      v-if="main_categories.length"
      class="home-page__category-slider"
      :slider-options="categorySliderOptions"
      :items="main_categories"
      v-slot="{ item }"
    >
      <MainCategoryItem :data="item" />
    </SliderComponent>
    <SliderComponent
      v-if="popular.length"
      class="home-page__product-slider"
      title="Популярные товары"
      buttonTitle="Все товары"
      :route="{
        name: 'catalog',
        query: {
          ordering: JSON.stringify([{ orderColumn: 'in_popular', orderBy: 'desc' }]),
          page_title: 'Популярные товары',
        },
      }"
      :slider-options="productSliderOptions"
      :items="popular"
      v-slot="{ item }"
    >
      <ProductCardComponent :data="item" />
    </SliderComponent>
    <SliderComponent
      v-if="new_products.length"
      class="home-page__product-slider"
      title="Новинки"
      buttonTitle="Все новинки"
      :route="{
        name: 'catalog',
        query: {
          ordering: JSON.stringify([{ orderColumn: 'created_at', orderBy: 'desc' }]),
          page_title: 'Новинки',
        },
      }"
      :slider-options="productSliderOptions"
      :items="new_products"
      v-slot="{ item }"
    >
      <ProductCardComponent :data="item" />
    </SliderComponent>
    <SliderComponent
      v-if="main_shares.length"
      class="home-page__promo-slider"
      title="Акции"
      buttonTitle="Все акции"
      :route="{ name: 'shares' }"
      :slider-options="promoSliderOptions"
      :items="main_shares"
      v-slot="{ item }"
    >
      <PromoComponent :data="item" />
    </SliderComponent>
    <SliderComponent
      v-if="goodDealsProducts.length"
      class="home-page__product-slider"
      title="Выгодные предложения"
      :slider-options="productSliderOptions"
      :items="goodDealsProducts"
      v-slot="{ item }"
    >
      <ProductCardComponent :data="item" />
    </SliderComponent>
    <SiteDescriptionComponent :marketplace="marketplace" />
    <SiteOffersComponent class="home-page__white" />
    <BrandsComponent :brands="main_brands" />
  </div>
</template>

<script>
import SliderComponent from "components/SliderComponent.vue";
import ProductCardComponent from "components/product/ProductCardComponent.vue";
import PromoComponent from "components/PromoComponent.vue";
import SlideComponent from "components/SlideComponent.vue";
import SiteDescriptionComponent from "views/home/components/SiteDescriptionComponent.vue";
import SiteOffersComponent from "views/home/components/SiteOffersComponent.vue";
import BrandsComponent from "views/home/components/BrandsComponent.vue";
import MainCategoryItem from "components/MainCategoryItem.vue";
import OpenCatalogButtonComponent from "components/header/components/OpenCatalogButtonComponent.vue";
import HOME_PAGE from "@/graphql/pages/HomePage.graphql";
import HOME_PAGE_ASYNC from "@/graphql/pages/HomePageAsync.graphql";
import HomeStoriesComponent from "views/home/components/HomeStoriesComponent.vue";

export default {
  name: "HomePage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: HOME_PAGE,
      })
      .then(({ data }) => {
        store.state.home_page.main_categories = data.categories_paginate.data;
        store.state.home_page.banner_shares = data.banner_shares;
        store.state.categories = data.categories;
        store.state.pages = data.pages;
        store.state.marketplace = data.marketplace;
        store.state.stories = data.stories;
        if (data.exchange_rates) store.state.exchange_rates = data.exchange_rates;
      })
      .catch(() => {});
  },
  beforeCreate() {
    this.$apollo
      .query({
        query: HOME_PAGE_ASYNC,
      })
      .then(({ data }) => {
        this.$store.state.home_page.main_shares = data.main_shares;
        this.$store.state.home_page.good_deal_products = data.good_deal_products;
        this.$store.state.home_page.new_products = data.new_products;
        this.$store.state.home_page.popular_products = data.popular_products;
        this.$store.state.home_page.main_brands = data.brands_is_main;
      })
      .catch(() => {});
  },
  data() {
    return {
      mainSliderOptions: {
        slidesPerView: 1,
        spaceBetween: 12,
        pagination: false,
        clickable: false,
        preloadImages: true,
        loop: true,
        autoplay: {
          delay: 10000,
        },
        breakpoints: {
          860: {
            slidesPerView: "auto",
            spaceBetween: 24,
          },
        },
      },
      productSliderOptions: {
        slidesPerView: 1.5,
        spaceBetween: 15,
        pagination: false,
        clickable: false,
        watchSlidesProgress: true,
        breakpoints: {
          460: {
            slidesPerView: 1.8,
            spaceBetween: 15,
          },
          540: {
            slidesPerView: 2.2,
            spaceBetween: 15,
          },
          740: {
            slidesPerView: 2.9,
            spaceBetween: 15,
          },
          860: {
            slidesPerView: 3.5,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 24,
          },
        },
      },
      categorySliderOptions: {
        slidesPerView: 1.3,
        spaceBetween: 16,
        pagination: false,
        clickable: false,
        breakpoints: {
          400: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          540: {
            slidesPerView: 2.5,
            spaceBetween: 24,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
          860: {
            slidesPerView: 4,
            spaceBetween: 24,
          },
        },
      },
      promoSliderOptions: {
        slidesPerView: 1.8,
        spaceBetween: 24,
        pagination: false,
        clickable: false,
        breakpoints: {
          480: {
            slidesPerView: 2.8,
            spaceBetween: 24,
          },
          640: {
            slidesPerView: 3.5,
            spaceBetween: 24,
          },
          860: {
            slidesPerView: 4,
            spaceBetween: 24,
          },
          940: {
            slidesPerView: 5,
            spaceBetween: 24,
          },
          1200: {
            slidesPerView: 6,
            spaceBetween: 24,
          },
        },
      },
    };
  },
  // mounted() {
  //   console.log(this.stories);
  // },
  computed: {
    slides() {
      return this.$store.state.home_page.banner_shares || [];
    },
    main_categories() {
      return this.$store.state.home_page.main_categories || [];
    },
    popular() {
      return this.$store.state.home_page.popular_products?.data || [];
    },
    new_products() {
      return this.$store.state.home_page.new_products?.data || [];
    },
    main_shares() {
      return this.$store.state.home_page.main_shares || [];
    },
    goodDealsProducts() {
      return this.$store.state.home_page.good_deal_products?.data || [];
    },
    marketplace() {
      return this.$store.state.marketplace;
    },
    main_brands() {
      return this.$store.state.home_page.main_brands || [];
    },
    stories() {
      return this.$store.state.stories || [];
    },
  },
  components: {
    HomeStoriesComponent,
    OpenCatalogButtonComponent,
    MainCategoryItem,
    BrandsComponent,
    SiteOffersComponent,
    SiteDescriptionComponent,
    SlideComponent,
    PromoComponent,
    ProductCardComponent,
    SliderComponent,
  },
  metaInfo: {
    title: "Торговая площадка товаров из Турции",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Торговая площадка товаров из Турции",
      },
    ],
    titleTemplate: null,
  },
};
</script>

<style lang="stylus">
.home-page {
  display: grid;
  grid-gap: 48px;
  padding: 32px 0 48px;

  +below(860px) {
    grid-gap: 30px;
    padding: 15px 0;
  }

  &__main-slider {
    +below(1320px) {
      padding-right: 0;

      .slider-arrows__item:last-child {
        right: 0;
      }
    }

    +below(860px) {
      padding-right: 15px;
    }
  }

  &__category-slider {
    +below(860px) {
      padding-right: 0;

      .slider__header {
        padding-right: 15px;
      }
    }
  }

  &__product-slider {
    +below(1024px) {
      padding-right: 0;

      .slider-arrows__item:last-child {
        right: 0;
      }

      .slider__header {
        padding-right: 15px;
      }
    }
  }

  &__promo-slider {
    border: 1px solid var(--blue-400);
    background: var(--white);
    padding-top: 48px;
    padding-bottom: 48px;

    +below(860px) {
      background: none;
      border: none;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;

      .slider__header {
        padding-right: 15px;
      }
    }
  }

  > .btn.btn--green-inv.btn--lg {
    display: none;
    justify-content: center;
    margin: -15px 15px;

    +below(860px) {
      display: inline-flex;
    }
  }

  &__white {
    border: 1px solid var(--blue-400);
    background: var(--white);
    padding-top: 48px;
    padding-bottom: 48px;

    +below(860px) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}
</style>
