var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page home-page"},[_c('HomeStoriesComponent',{attrs:{"stories":_vm.stories}}),(_vm.slides.length)?_c('SliderComponent',{staticClass:"home-page__main-slider",attrs:{"items":_vm.slides,"slider-options":_vm.mainSliderOptions},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('SlideComponent',{attrs:{"data":item}})]}}],null,false,1918752237)}):_vm._e(),_c('OpenCatalogButtonComponent'),(_vm.main_categories.length)?_c('SliderComponent',{staticClass:"home-page__category-slider",attrs:{"slider-options":_vm.categorySliderOptions,"items":_vm.main_categories},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('MainCategoryItem',{attrs:{"data":item}})]}}],null,false,3235735899)}):_vm._e(),(_vm.popular.length)?_c('SliderComponent',{staticClass:"home-page__product-slider",attrs:{"title":"Популярные товары","buttonTitle":"Все товары","route":{
      name: 'catalog',
      query: {
        ordering: JSON.stringify([{ orderColumn: 'in_popular', orderBy: 'desc' }]),
        page_title: 'Популярные товары',
      },
    },"slider-options":_vm.productSliderOptions,"items":_vm.popular},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('ProductCardComponent',{attrs:{"data":item}})]}}],null,false,282466501)}):_vm._e(),(_vm.new_products.length)?_c('SliderComponent',{staticClass:"home-page__product-slider",attrs:{"title":"Новинки","buttonTitle":"Все новинки","route":{
      name: 'catalog',
      query: {
        ordering: JSON.stringify([{ orderColumn: 'created_at', orderBy: 'desc' }]),
        page_title: 'Новинки',
      },
    },"slider-options":_vm.productSliderOptions,"items":_vm.new_products},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('ProductCardComponent',{attrs:{"data":item}})]}}],null,false,282466501)}):_vm._e(),(_vm.main_shares.length)?_c('SliderComponent',{staticClass:"home-page__promo-slider",attrs:{"title":"Акции","buttonTitle":"Все акции","route":{ name: 'shares' },"slider-options":_vm.promoSliderOptions,"items":_vm.main_shares},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('PromoComponent',{attrs:{"data":item}})]}}],null,false,3471040341)}):_vm._e(),(_vm.goodDealsProducts.length)?_c('SliderComponent',{staticClass:"home-page__product-slider",attrs:{"title":"Выгодные предложения","slider-options":_vm.productSliderOptions,"items":_vm.goodDealsProducts},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('ProductCardComponent',{attrs:{"data":item}})]}}],null,false,282466501)}):_vm._e(),_c('SiteDescriptionComponent',{attrs:{"marketplace":_vm.marketplace}}),_c('SiteOffersComponent',{staticClass:"home-page__white"}),_c('BrandsComponent',{attrs:{"brands":_vm.main_brands}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }