<template>
  <div class="default-modal stories-modal" @contextmenu.prevent>
    <div class="stories-modal__header">
      <div class="stories-modal__header-progress">
        <div
          class="stories-modal__header-progress stories-modal__header-progress--white"
          :style="progressWidth"
        />
      </div>
      <button class="stories-modal__close" @click="$emit('close')">
        <FileIconComponent name="close" />
      </button>
    </div>
    <div class="default-modal__body stories-modal__body">
      <div class="stories-modal__slides">
        <div class="swiper" ref="items">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, i) in stories" :key="i" @click="handleClick">
              <video
                v-if="videoTypes.includes(item.file.type)"
                :src="$store.state._env.MEDIA_ENDPOINT + item.file.path"
                :ref="`videoRefs[${i}]`"
                class="stories-modal__video"
                playsinline
              />
              <img
                v-else
                :src="$store.state._env.MEDIA_ENDPOINT + item.file.path"
                :alt="item.file.alt"
                class="stories-modal__img"
              />
              <span v-if="item.description" class="stories-modal__description">{{ item.description }}</span>
              <!--              <ImgComponent :src="item.file.path" class="stories-modal__img" />-->
              <!--              <ImgComponent src="/static/images/story.png" class="stories-modal__img" />-->
            </div>
          </div>
        </div>
        <div v-if="stories.length !== 1" class="stories-modal__arrows">
          <button
            type="button"
            title="Влево"
            class="stories-modal__arrows-item stories-modal__arrows-item--left"
            :disabled="swiper?.isBeginning"
            @click="goToPreviousSlide"
          >
            <RemixIconComponent category="System" name="arrow-left-s-line" />
          </button>
          <button
            type="button"
            title="Вправо"
            class="stories-modal__arrows-item stories-modal__arrows-item--right"
            :disabled="swiper?.isEnd"
            @click="goToNextSlide"
          >
            <RemixIconComponent category="System" name="arrow-right-s-line" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import FileIconComponent from "components/FileIconComponent.vue";

export default {
  name: "StoriesModal",
  emits: ["close"],
  props: {
    story: Object,
    stories: Array,
    index: Number,
  },
  data() {
    return {
      activeIndex: 0,
      val: 0, // значение прогрессбара сверху
      swiper: null,
      intervalID: null,
      swiperDelay: 15000, // сколько история идет
      remainingTime: 15000, // для отслеживания оставшегося времени
      startTime: 0, // для отслеживания времени начала
      isPaused: false, // для отслеживания состояния паузы
      elapsedTime: 0, // для отслеживания времени, прошедшего с последнего запуска
      videoTypes: ["mp4", "avi", "webm", "mkv", "flv", "mov", "wmv"],
    };
  },
  mounted() {
    this.activeIndex = this.index;
    require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, {
        speed: 700,
        mousewheel: true,
        initialSlide: this.index,
        on: {
          init: this.startInterval,
          slideChange: this.onSlideChange,
          touchStart: this.pauseInterval,
          touchEnd: this.resumeInterval,
        },
      });
    });
  },
  beforeDestroy() {
    clearInterval(this.intervalID);
    if (this.swiper) {
      this.swiper.destroy();
    }
  },
  watch: {
    swiper() {
      this.blockSwipe();
    },
  },
  methods: {
    handleClick(event) {
      if (window.innerWidth < 650) {
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - rect.left;

        if (x <= rect.width / 2) {
          if (!this.swiper.isBeginning) {
            this.goToPreviousSlide();
          }
        } else {
          if (!this.swiper.isEnd) {
            this.goToNextSlide();
          }
        }
      }
    },
    startInterval() {
      this.startTime = Date.now();
      clearInterval(this.intervalID);
      this.intervalID = setInterval(() => {
        this.updateProgress();
      }, 100);
      this.pauseAllVideos();
      this.playVideo(this.activeIndex);
    },
    updateProgress() {
      const currentTime = Date.now();
      const elapsedTime = currentTime - this.startTime;
      this.val = (elapsedTime / this.remainingTime) * 100;
      if (this.val >= 100) {
        this.nextSlideOrClose();
      }
    },
    pauseInterval() {
      if (!this.isPaused) {
        this.isPaused = true;
        clearInterval(this.intervalID);
        const elapsedTime = Date.now() - this.startTime;
        this.remainingTime -= elapsedTime;
        this.pauseVideo(this.activeIndex);
      }
    },
    resumeInterval() {
      if (this.isPaused) {
        this.isPaused = false;
        this.startTime = Date.now();
        this.intervalID = setInterval(() => {
          const elapsedSinceResume = Date.now() - this.startTime;
          this.val = (1 - (this.remainingTime - elapsedSinceResume) / this.swiperDelay) * 100;
          if (this.val >= 100) {
            this.nextSlideOrClose();
          }
        }, 100);
        this.playVideo(this.activeIndex);
      }
    },
    resetProgress() {
      this.val = 0;
      this.remainingTime = this.swiperDelay;
    },
    goToNextSlide() {
      if (this.swiper) {
        this.swiper.slideNext();
      }
    },
    goToPreviousSlide() {
      if (this.swiper) {
        this.swiper.slidePrev();
      }
    },
    onSlideChange() {
      if (this.swiper) {
        this.activeIndex = this.swiper.activeIndex;
      }
      this.resetProgress();
      this.startInterval();
      this.blockSwipe();
    },
    playVideo(index) {
      if (this.videoTypes.includes(this.stories[index]?.file.type)) {
        this.$refs[`videoRefs[${index}]`][0].play();
      }
    },
    pauseVideo(index) {
      if (this.videoTypes.includes(this.stories[index]?.file.type)) {
        this.$refs[`videoRefs[${index}]`][0].pause();
      }
    },
    pauseAllVideos() {
      for (let i = 0; i < this.stories.length; i++) {
        if (this.videoTypes.includes(this.stories[i]?.file.type)) {
          this.$refs[`videoRefs[${i}]`][0].pause();
        }
      }
    },
    nextSlideOrClose() {
      if (this.activeIndex + 1 >= this.stories.length) {
        this.$emit("close");
      } else {
        this.activeIndex += 1;
        this.swiper.slideNext();
        this.resetProgress();
        this.startInterval();
      }
    },
    blockSwipe() {
      if (this.activeIndex + 1 === this.stories.length) {
        this.swiper.allowSlidePrev = true;
        this.swiper.allowSlideNext = false;
        this.swiper.init();
      } else if (this.activeIndex === 0) {
        this.swiper.allowSlideNext = true;
        this.swiper.allowSlidePrev = false;
        this.swiper.init();
      } else {
        this.swiper.allowSlideNext = true;
        this.swiper.allowSlidePrev = true;
        this.swiper.init();
      }
    },
  },
  computed: {
    progressWidth() {
      return { width: `${this.val}%`, transition: "width 0.1s linear" };
    },
  },
  components: {
    FileIconComponent,
    RemixIconComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/textcut.styl"
.modal__content {
  +below(650px) {
    align-items center
  }
}

.stories-modal {
  width 100%
  //max-width 460px
  //height 780px
  max-width 560px
  height 780px
  max-height 780px
  border-radius 5px
  padding 0
  position relative
  //background none
  +below(580px) {
    max-width 460px
  }
  +below(470px) {
    margin -5px auto
    max-width 375px
    max-height 650px
  }

  &__header {
    padding 10px 10px 0 10px
    z-index 2
  }

  &__close {
    absolute top 20px right 10px
    width 30px
    height 30px
    border none
    background none
    cursor pointer
  }

  &__header-progress {
    height 3px
    background #D9D9D9
    border-radius 100px
    position relative

    &--white {
      //background #fef8f8
      background #ffffff
      absolute left 0 top 0
      z-index 1
    }
  }

  &__body {
    absolute left 0 top 0
    //background red
    height 100%
  }

  &__slides {
    position relative
    width 100%
    height 100%
  }

  .swiper {
    width 100%
    border-radius 5px
    //max-width 460px
    max-width 560px
    height 100%
    //max-height 780px
    max-height 780px
    +below(580px) {
      max-width 460px
    }
    +below(470px) {
      max-width 375px
    }
  }

  &__img {
    width 100%
    height 100%
    //border-radius 5px
    object-fit cover
  }

  &__video {
    width 100%
    height 100%
    max-width 560px
    max-height 780px
    border-radius 5px
    object-fit cover
    +below(580px) {
      max-width 460px
    }
    +below(470px) {
      max-width 375px
    }
  }

  &__description {
    max-width 540px
    word-break break-word
    absolute left 10px bottom 10px
    textcut(5)
    color #787878
    +below(580px) {
      max-width 440px
    }
    +below(470px) {
      max-width 355px
    }
  }

  &__arrows {
    z-index: 1;
    //+below(860px) {
    //  display none
    //}
    +below(650px) {
      display none
    }
  }

  &__arrows-item {
    z-index: 1;
    margin auto
    background: none
    border: none
    box-sizing: border-box;
    //border-radius: 40px;
    width 44px
    height 44px
    display flex
    align-items center
    justify-content center
    cursor pointer
    transition var(--transition)
    //+below(650px) {
    //  height 100%
    //  width 50%
    //
    //  .icon {
    //    display none
    //  }
    //}

    //&:not([disabled]):hover {
    //  border-color var(--accent)
    //
    //  .icon svg path {
    //    fill var(--accent)
    //  }
    //}

    &[disabled] {
      cursor not-allowed

      .icon svg path {
        //fill #ACACAC
        fill #464646
        //fill #908b8b
      }
    }

    .icon {
      width 44px
      height 44px

      svg path {
        fill #fff
      }
    }

    &--left {
      absolute left -100px top bottom
      margin-left 0
      margin-right auto
      //+below(1360px) {
      //  left -15px
      //}
      +below(750px) {
        left -50px
      }
      //+below(650px) {
      //  left 0
      //}
    }

    &--right {
      absolute right -100px top bottom
      margin-left auto
      margin-right 0
      //+below(1360px) {
      //  right -15px
      //}
      +below(750px) {
        right -50px
      }
      //+below(650px) {
      //  right 0
      //}
    }
  }
}
</style>
