<template>
  <div v-if="story">
    <div v-if="story.shop" @click="openStoriesModal" class="home-story">
      <ImgComponent v-if="story.shop.head_img" :head_img="story.shop.head_img" class="home-story__img" />
      <span v-if="story.shop.title" class="home-story__title">{{ story.shop.title }}</span>
    </div>
    <div v-else @click="openStoriesModal" class="home-story">
      <ImgComponent src="/static/images/sasahub.webp" class="home-story__img" />
      <span class="home-story__title">SASAHUB</span>
    </div>
  </div>
</template>

<script>
import StoriesModal from "components/modals/components/StoriesModal.vue";

export default {
  name: "HomeStoryComponent",
  props: {
    story: {
      type: Object,
      default: () => {},
    },
    stories: Array,
    index: Number,
  },
  methods: {
    openStoriesModal() {
      this.$store.state._modals.push({
        component: StoriesModal,
        options: {
          stories: this.stories,
          index: this.index,
        },
        stories: true,
      });
    },
  },
};
</script>

<style lang="stylus">
.home-story {
  max-height 85px
  display flex
  flex-direction column
  align-items center
  gap 3px
  flex-shrink 0
  cursor pointer
  +above(1576px) {
    gap 10px
  }

  &__img {
    width 60px
    height 60px
    border-radius 100px
  }

  &__title {
    font-size 0.75rem
    line-height 15px
  }
}
</style>
